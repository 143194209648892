import React, { useEffect } from "react";
// import ReactTypingEffect from "react-typing-effect";
import Typewriter from "typewriter-effect";
import "bootstrap/dist/css/bootstrap.css";

import { navigate } from "hookrouter";

import "./Matrix.css";

const Matrix = (props) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      console.log("This will run after 1 second!");
      navigate("/about");
      window.location.reload();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      {/* <div className="home-container">ryan k. gregory</div> */}
      {/* <div className="wrapper"> */}
      <div className="matrix-container">
        <div className="matrix-text-container">
          <Typewriter
            options={{
              strings: [
                "hello world...",
              ],
              autoStart: true,
              loop: false,
              wrapperClassName: "type-text",
              delay: 30,
              deleteSpeed: 30,
              //   cursor: "Pipe character",
              cursorClassName: "cursor",
              devMode: true,
            }}
          />
          {/* <ReactTypingEffect
            className="type-text"
            text={[
              "initializing protocol E347-B: 'MAKE COOL-LOOKING PORTFOLIO SITE'...",
              "...",
            ]}
            staticText={">"}
            speed={25}
            eraseSpeed={25}
            typingDelay={1000}
          /> */}
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

export default Matrix;
