import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Code.css";
import Nav from "../Nav/Nav.js";
import cloudsurf from "../../resources/cs-web(1).jpg";

const Code = (props) => {
  //   const [init, setInit] = useState(false);

  return (
    <React.Fragment>
      <Nav />
      {/* <div className="home-container">ryan k. gregory</div> */}
      <div className="code-container">
        <div className="text-container">
          <h1 className="header">&#62; code</h1>
          <p className="code-text">
            <span className="boldy">technologies:</span> react, react native,
            aws &#40;lambda, amplify, dynamoDb, route 53, cloudfront, s3&#41;,
            git <br></br>
            <span className="boldy">languages:</span> js, html, css, scss
          </p>
          <div className="gallery-container">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="subheader">cloudsurf software</h2>
                <a target="_blank" href="https://cloudsurfsoftware.com/">
                  <img className="project-img" src={cloudsurf} />
                </a>
              </div>
              <div className="col-lg-4">
                <h2 className="subheader">king yosef</h2>
                <a target="_blank" href="https://kingyosef.com/">
                  <img
                    className="project-img"
                    src={
                      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/kbd/projects/king-yosef-screen-min.png"
                    }
                  />
                </a>
              </div>
              <div className="col-lg-4">
                <h2 className="subheader">bowties bridal</h2>
                <a target="_blank" href="https://bowtiesbridal.com/">
                  <img
                    className="project-img"
                    src={
                      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/kbd/projects/bowties-screen.jpg"
                    }
                  />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <h2 className="subheader">donovan j. campbell</h2>
                <a target="_blank" href="https://donovanjcampbell.com/">
                  <img
                    className="project-img"
                    src={
                      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/kbd/projects/donovan-campbell-screen-min.png"
                    }
                  />
                </a>
              </div>
              <div className="col-lg-4">
                <h2 className="subheader">ash vintage</h2>
                <a target="_blank" href="https://ashvintage.com/">
                  <img
                    className="project-img"
                    src={
                      "https://photo-hosting-bucket.s3-us-west-1.amazonaws.com/kbd/projects/ash-vintage-screen.png"
                    }
                  />
                </a>
              </div>
              <div className="col-lg-4">
                <h2 className="subheader">
                  bizzy body &#40;pre-alpha, ask for testflight&#41;
                </h2>

                <img
                  className="project-img"
                  src={
                    "https://www.upwork.com/att/download/portfolio/persons/uid/428579780447858688/profile/projects/files/1cb35a1a-12c9-459a-a450-5b87f5d8cf73"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Code;
