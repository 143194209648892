import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./About.css";
import Nav from "../Nav/Nav.js";

const About = (props) => {
  //   const [init, setInit] = useState(false);

  return (
    <React.Fragment>
      <Nav />
      {/* <div className="home-container">ryan k. gregory</div> */}
      <div className="ascii">
        <pre>{`


        '||              
... ..   ||  ..    ... . 
 ||' ''  || .'    || ||  
 ||      ||'|.     |''   
.||.    .||. ||.  '||||. 
                 .|....' 
                         

           `}</pre>
      </div>
      <div className="about-container">
        <div className="text-container">
          <h1 className="header">&#62; about</h1>
          <p className="about-text">
            i am a programmer, video artist, and problem-solver&#8482;. <br></br>currently
            employed as a software engineer at <a href="https://sourcetoad.com/" target="_blank">Sourcetoad</a>.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
