import React from "react";
// import About from "../components/About/About";
// import Home from "../components/Home/Home";
import About from "../components/About/About";
import Code from "../components/Code/Code";
import Art from "../components/Art/Art";
import Contact from "../components/Contact/Contact";
import Matrix from "../components/Matrix/Matrix";
// import Contact from "../components/Contact/Contact";
// import Projects from "../components/Projects/Projects";
// import Services from "../components/Services/Services";

const routes = {
  "/": () => <Matrix />,
  "/about": () => <About />,
  "/code": () => <Code />,
  "/art": () => <Art />,
  "/contact": () => <Contact />,
  "/matrix": () => <Matrix />,

  // "/projects": () => <Projects />,
  // "/services": () => <Services />,
};
export default routes;
