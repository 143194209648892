import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Contact.css";
import Nav from "../Nav/Nav.js";

const Contact = (props) => {
  //   const [init, setInit] = useState(false);

  return (
    <React.Fragment>
      <Nav />
      {/* <div className="home-container">ryan k. gregory</div> */}
      <div className="contact-container">
        <div className="text-container">
          <h1 className="header">&#62; contact</h1>
          <p className="code-text">
            <a href="tel:+7024281897">702-428-1897</a>
            <br></br>
            <a href="mailto:rkgregory@gmail.com">rkgregory@gmail.com</a>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
