import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./Art.css";
import Nav from "../Nav/Nav.js";

const Art = (props) => {
  //   const [init, setInit] = useState(false);

  return (
    <React.Fragment>
      <Nav />
      {/* <div className="home-container">ryan k. gregory</div> */}
      <div className="art-container">
        <div className="text-container">
          <h1 className="header">&#62; art</h1>
          <p className="art-text">
            all commissions closed. portfolio is currently being updated :^&#41;
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Art;
